import React, { useEffect, useState } from 'react'
import { Col, Container, FormSelect, Row } from "react-bootstrap";
import LoginFooter from "./LoginFooter";
import GatewaySlider from "./GatewaySlider";
import { Form } from 'react-router-dom';
import { get_user_balance_data, get_user_coin_data } from '../../common/Api';
import moment from 'moment';
import { IoChevronDownOutline } from 'react-icons/io5';

const BalanceHistroy = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  const [coin, setCoin] = useState([])
  const [selectedCoin, setSelectedCoin] = useState('')
  const [balance, setBalance] = useState(null)
  const userGetToken = async () => {
    try {
      const data = await get_user_coin_data(user._id, token)
      //console.log(data)
      setCoin(data.coin)

    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    userGetToken()
  }, [])

  const handleSubmitData = async (e) => {
    try {
      const data = await get_user_balance_data(user._id, token, e.target.value)
      console.log(data)
      setBalance(data.data)
    } catch (error) {
      console.log(error)
    }
  }

  console.log(balance)

  return (
    <>
      <Container>

        <Row className="py-5">
          <Col lg={11} className="mx-auto">
            <div className='mb-5'>
              <h2 className='text-center'>Balance Histroy</h2>
            </div>
            <Row>
              <Col md={3}>
                <label className='text_slate mb-2 fs14 fw-semibold'>Coin Type</label>
                <div className='position-relative'>
                <IoChevronDownOutline className='position-absolute end-0 me-2 translate-middle-y top-50 z-1 text_slate' />
                <select  onChange={handleSubmitData} className='form-control text_slate'>
                  <option value="">All Coin</option> {/* Set a value for the default option */}
                  {
                    coin?.map((item) => (
                      <option key={item?._id} value={item?._id}>{item?.name}</option>
                    ))
                  }
                </select>
                </div>


              </Col>


              <Col xs={12} className='my-4 history_result_wrapper mt-2 py-4'>
                <div className='history_results '>
                  <div className='empty text-center'>
                    <div className="card">
                      <div className="table-responsive text-nowrap ">
                        {
                          balance ? <table className="table">
                            <thead className="text-white">
                              <tr>
                                <th>S.No</th>
                                <th>Coin name</th>
                                <th>Address</th>
                                <th>Available Amount</th>
                                <th>Created At</th>
                              </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">

                              <tr>
                                <td>1 </td>
                                <td>{balance?.coinId?.name}</td>
                                <td> {balance?.address}</td>
                                <td> $ {balance?.amount}</td>
                                <td>{moment(balance?.createdAt).format('DD MMMM YYYY')}</td>
                              </tr>

                            </tbody>
                          </table> : <p style={{ color: "#fff" }}>
                            No reord found!
                          </p>
                        }

                        <div className="d-flex justify-content-center"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row >

            </Row>

          </Col>

        </Row>

      </Container>
    </>
  )
}

export default BalanceHistroy