import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileSidebar from "./ProfileSidebar";
import LoginFooter from "../LoginFooter";
import GatewaySlider from "../GatewaySlider";
import { FaCamera } from "react-icons/fa";
import { get_user_single_data, update_user_profile_image, upload } from '../../../common/Api'
import { useSelector } from 'react-redux'
import moment from 'moment';
import toast from "react-hot-toast";

const PersonalData = () => {
  const [profileimage, setProfileImage] = useState(null)

  const user = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  const [users, setUsers] = useState({})

  const [profile, setProfile] = useState({})

  const handleFileChange = async (event) => {
    try {
      setProfileImage(event.target.files[0]);
      const formData = new FormData();
      formData.append('image', event.target.files[0]);
      const data = await update_user_profile_image(user._id, token, formData);
      if (data.status == true) {
        toast.success(data.message)

      }
      console.log(data)
    } catch (error) {
      console.log(error)
    }

  };


  const user_data = async () => {
    try {
      const data = await get_user_single_data(user._id, token)
      console.log(data)
      setUsers(data.data)
      setProfile(data.profile)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    user_data()
  }, [profileimage])


  return (
    <>
      <Container className="">
        <Row className="py-5">

          <div data-test-id="info" className="css-1s9dyf9 e1vkrkhy12">
            <div className="css-1b0by8p e1vkrkhy13 text-end fs14 fw-medium text_slate">
              <span dir="auto">Date registered</span>:{" "}
              <span className="css-10iahqc e1vkrkhy14 ">{moment(user?.createdAt).format('MMMM DD, YYYY')}</span>
            </div>
            <div className="css-1b0by8p e1vkrkhy13 text-end fs14 fw-medium text_slate">
              <span dir="auto">Profile ID</span>: {users?.btucode}
            </div>
          </div>
        </Row>

        <Row className="pb-5">
          <Col lg={11} className="mx-auto">
            <div className="Profile__content">
              <div className="Profile__Personal">
                <div
                  data-test-id="personal-intro-wrapper "
                  className="css-19hj6l2 e1gftbhh0"
                >
                  <div
                    data-test-id="personal-avatar-wrapper"
                    className="css-9nikbl e1gftbhh1 photoarea text-center align-content-center"
                  >
                    {
                      users?.profileImg ? <img src={`${upload}/${users?.profileImg}`} /> : <FaCamera className="fs-1 text_slate" />
                    }

                  </div>
                  <div className="css-ymxu6 ed4cmvm0 ">
                    <button
                      data-test-id="upload-avatar-button"
                      className="css-r0u99n ed4cmvm1 position-relative z-3"
                    >
                      <input type="file" className="position-absolute top-0 start-0 w-100 z-1 opacity-0 h-100" onChange={handleFileChange} />
                      <span className="css-108vnzd ed4cmvm2">+</span>
                      <span className="css-hsk5u7 ed4cmvm3">
                        <span dir="auto">Upload a photo</span>
                      </span>
                    </button>
                  </div>
                  <p className="css-qy1nd8">
                    <span dir="auto">
                      Your photo will be displayed in direct messages, public chats and
                      rankings.
                    </span>
                  </p>
                </div>
                <div data-test-id="statement-block" className="css-1ej6zhf e18325940">
                  <div className="css-y6q4g1 e18325941">
                    <h4 className="em52qqg0 css-1envvlz">
                      <span>
                        <span dir="auto">Account statement</span>:{" "}
                        <span
                          data-test-id="statement-period"
                          className="css-jyilpz e1x14s8e1"
                        >
                          <span dir="auto">monthly</span>
                        </span>
                      </span>
                    </h4>
                    <div className="css-191gowv e1vkrkhy1">
                      <span dir="auto">
                        Get detailed information on your trading account for the selected
                        period.
                      </span>
                    </div>
                  </div>
                  <div data-test-id="datepicker-wrapper" className="css-1ahhdvi e18325942">

                    <a
                      href="javascript:void(0);"
                      className="css-195nb81 e18325946"
                      data-test-id="datepicker-export-button"
                    >
                      <span>
                        <span dir="auto">Create a request</span>
                      </span>
                    </a>
                  </div>
                </div>
                <div data-test-id="personal-details-block" className="css-ujb5j1 e1vkrkhy0">
                  <h4 className="em52qqg0 css-1envvlz">
                    <span>
                      <span dir="auto">Contact info</span>:
                    </span>
                  </h4>
                  {
                    users.profile == 1 ? <>
                      <p style={{ margin: 0 }}>
                        <b>Date of Birth: </b>
                        {profile?.dob ? moment(profile.dob).isValid() ? moment(profile.dob).format('MMMM DD, YYYY') : 'Invalid Date' : 'Not Available'}
                      </p>
                      <p style={{ margin: 0 }}><b>Country:  </b> {profile?.country}</p>
                      <p style={{ margin: 0 }}><b>City: </b> {profile?.city}</p>
                      <p style={{ margin: 0 }}><b>Citizenship: </b> {profile?.country}</p>
                      <p style={{ margin: 0 }}><b>Residential Address: </b> {profile?.area} {profile?.postcode} ,  {profile?.city} {profile?.country}</p>
                    </> : <>

                      <div size={14} className="css-173hohc e1awuv9z0">
                        <div className="css-19dfyop e1awuv9z1">
                          <div>
                            <span dir="auto">
                              You haven't filled in your contact details yet.
                            </span>
                            <br />
                            <a href="/profile/personal/verification">
                              <span dir="auto">Please verify your account</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  }



                  <div data-test-id="personal-details-hint" className="css-x31wn9 ewnj0b30">
                    <span dir="auto">
                      If you would like to rectify and/or manage your data, please contact{" "}
                      <a href="mailto:support@iqoption.com">
                        <bdi>support@metabt.com</bdi>
                      </a>
                      .
                    </span>
                  </div>
                </div>
                <div
                  id="accessToMyData"
                  data-test-id="personal-access-data-block"
                  className="css-ujb5j1 e1vkrkhy0"
                >
                  <div data-test-id="personal-access-data-toggle-mobile">
                    <h4 className="em52qqg0 css-1envvlz">
                      <span>
                        <span dir="auto">Access My Data</span>
                      </span>
                    </h4>
                    <div className="css-191gowv e1vkrkhy1">
                      <span dir="auto">
                        You can view your personal information that you have provided to us
                        by category.
                      </span>
                    </div>
                  </div>
                  <div data-test-id="personal-access-data-toggle" className="css-eqcq9o">
                    <span dir="auto">Show my data</span>
                  </div>
                </div>
              </div>
            </div>

          </Col>

        </Row>

      </Container>
      {/* <Container fluid>
        <GatewaySlider />
        <LoginFooter />
      </Container> */}
    </>
  );
};

export default PersonalData;
