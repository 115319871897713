
import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
  Container,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
} from "react-bootstrap";
import { MdOutlineAttachEmail } from "react-icons/md";
import GatewaySlider from "../GatewaySlider";
import LoginFooter from "../LoginFooter";
import { Link } from "react-router-dom";
import { get_user_single_data, update_user_profile_data } from "../../../common/Api";

const AccountVerification = () => {

  const user = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  const [users, setUsers] = useState({})
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [dob, setDob] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [code, setCode] = useState('')
  const [profileimage, setProfileImage] = useState(null)
  

  const user_data = async () => {
    try {
      const data = await get_user_single_data(user._id, token)
      console.log(data)
      setUsers(data.data)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    user_data()
  }, [])



  const handleProfileData = async () => {
    try {
      const country = document.querySelector("#country").value;
      const submitdata = {
        userId: user._id,
        firstname: firstname,
        lastname: lastname,
        dob: dob,
        country: country,
        city: city,
        area: address,
        postcode: code,
        matchId: user._id
      }
      const data = await update_user_profile_data(user._id, token, submitdata)
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }


  const handleFileChange = async (event) => {
    // try {
    //   setProfileImage(event.target.files[0]);
    //   const formData = new FormData();
    //   formData.append('image', event.target.files[0]);
    //   const data = await update_user_profile_image(user._id, token, formData);
    //   if (data.status == true) {
    //     toast.success(data.message)
        
    //   }
    //   console.log(data)
    // } catch (error) {
    //   console.log(error)
    // }

  };

  return (
    <>
      
        <div className="row">
          <div className="mx-auto col-xl-7 col-lg-9 col-10 py-5">
            <div className="mb-5">
              <h2 className="text-center text_slate">Account Verification</h2>
            </div>
            <div className="row d-flex align-items-start acc_verification">
              <div className="col-xl-4 col-lg-5">
                <div
                  class="nav flex-lg-column flex-row justify-contet-lg-start justify-content-md-between justify-content-center nav-pills me-md-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    class="nav-link text-white bg_orng mb-3 text-center active basi"
                    id="v-pills-email-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-email"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-email"
                    aria-selected="true"
                  >
                    Email Confirmation
                  </button>
                  <button
                    class="nav-link text-white bg_orng mb-3 text-center"
                    id="v-pills-personaldets-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-personaldets"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-personaldets"
                    aria-selected="false"
                  >
                    Personal Details
                  </button>

                  {/* <button
                    class="nav-link text-white bg_orng mb-3 text-center"
                    id="v-pills-identityproof-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-identityproof"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-identityproof"
                    aria-selected="false"
                  >
                    Proof of Identity
                  </button> */}
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 ">
                <div class="tab-content w-100" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-email"
                    role="tabpanel"
                    aria-labelledby="v-pills-email-tab"
                    tabindex="0"
                  >
                    <div
                      data-test-id="success-state-container"
                      className="css-1curc4z eve91l35"
                    >
                      <div
                        data-test-id="header-title"
                        className="css-ca5sv6 ekuyjan2"
                      >
                        <h4 className="text-center text_slate">
                          {" "}
                          Email confirmation{" "}
                        </h4>
                      </div>
                      <div className="text-center py-4">
                        <MdOutlineAttachEmail className="fs4r text-center text_slate" />
                      </div>
                      <div className="css-dz7m1u e1cpc1bm0 fs-6 text_green mb-4 text-center">
                        <span>
                          Email <b>{users?.email}</b> confirmed
                          successfully
                        </span>
                      </div>

                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-personaldets"
                    role="tabpanel"
                    aria-labelledby="v-pills-personaldets-tab"
                    tabindex="0"
                  >

                    {
                      users?.profile == '1' ? <>
                        <div
                          data-test-id="success-state-container"
                          className="css-1curc4z eve91l35"
                        >
                          <div
                            data-test-id="header-title"
                            className="css-ca5sv6 ekuyjan2"
                          >
                            <h4 className="text-center text_slate">
                              {" "}
                              Profile confirmation{" "}
                            </h4>
                          </div>
                          <div className="text-center py-4">
                            <MdOutlineAttachEmail className="fs4r text-center text_slate" />
                          </div>
                          <div className="css-dz7m1u e1cpc1bm0 fs-6 text_green mb-4 text-center">
                            <span>
                             Successfully! Update Profile
                            </span>
                          </div>

                        </div>
                      </> : <>
                        <div>
                          <Form className="mb-5">
                            <h5 className="text_grey  text-center">Personal Info</h5>
                            <p className="text_slate fs14 text-center mb-4">
                              Provide your personal data exactly as it appears on your
                              ID to avoid verification issues in the future.
                            </p>
                            <FormGroup
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <FormLabel className="text_slate fw-semibold">
                                First Name
                              </FormLabel>
                              <FormControl type="text" placeholder="" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </FormGroup>
                            <FormGroup
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <FormLabel className="text_slate fw-semibold">
                                Last Name
                              </FormLabel>
                              <FormControl type="text" placeholder="" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </FormGroup>

                            <FormGroup
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <FormLabel className="text_slate fw-semibold">
                                Date of Birth
                              </FormLabel>
                              <FormControl type="date" placeholder="dd.mm.yy" value={dob} onChange={(e) => setDob(e.target.value)} />
                            </FormGroup>
                            <FormLabel className="text_slate fw-semibold">
                              Country of Citizenship
                            </FormLabel>
                            <FormSelect id="country">
                              <option value='India'>India</option>
                              <option value='USA'>USA</option>
                              <option value='Dubai' >Dubai</option>
                            </FormSelect>
                          </Form>

                          <Form className="mb-5">
                            <h5 className="text_grey text-center">
                              Address of Residence
                            </h5>
                            <p className="text_slate mb-4 fs14 text-center">
                              Please make sure the address is correct.
                            </p>

                            <FormGroup
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <FormLabel className="text_slate fw-semibold">
                                City
                              </FormLabel>
                              <FormControl type="text" placeholder="" value={city} onChange={(e) => setCity(e.target.value)} />
                            </FormGroup>
                            <FormGroup
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <FormLabel className="text_slate fw-semibold">
                                Residential Address
                              </FormLabel>
                              <FormControl type="text" placeholder="" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </FormGroup>

                            <FormGroup
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <FormLabel className="text_slate fw-semibold">
                                Postal Code
                              </FormLabel>
                              <FormControl type="text" placeholder="" value={code} onChange={(e) => setCode(e.target.value)} />
                            </FormGroup>
                            <div className="d-flex align-items-center justify-cotnent-center">
                              <button className="bg_green text-white w-50 border-0 px-4 py-2 my-3 mx-auto" onClick={handleProfileData}>
                                Submit
                              </button>
                            </div>
                          </Form>
                        </div>
                      </>
                    }





                  </div>
                  {/* <div
                    class="tab-pane fade"
                    id="v-pills-identityproof"
                    role="tabpanel"
                    aria-labelledby="v-pills-identityproof-tab"
                    tabindex="0"
                  >
                    <div>
                      <h4 className="text-center text_slate">
                        Proof of Identity
                      </h4>
                      <p className="text-center text_slate fs14 fw-semibold w-75 mx-auto">
                        AML laws and regulations require us to verify your
                        identity. This keeps our platform secure and enables us to
                        process payments faster. The documents you are about to
                        send must be clearly visible. For more information,
                        <Link to="#" className="text_orng">
                          click here.
                        </Link>
                      </p>

                      <div className="documentupload text-center">
                        <div className="imagearea">

                        </div>
                        <button className="text-white btndark border-0 px-5 py-2 mb-1 position-relative">
                          <input type="file" className="position-absolute start-0 top-0 opacity-0 w-100 h-100" onChange={handleFileChange}/>
                          Upload Document
                        </button>
                        <p className="text-center text_slate fs11 fw-semibold w-75 mx-auto">
                          Upload a JPG, PNG or PDF file, max size 15 MB
                        </p>

                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

            </div>
          </div>
        </div>
   
      <Container fluid>
        {/* <GatewaySlider /> */}
        <LoginFooter />
      </Container>
    </>
  );
};

export default AccountVerification;
