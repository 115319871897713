import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import EventBus from "./common/EventBus";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import { Toaster } from "react-hot-toast";

import Header from "./components/Iqoption/header/Header";
import Headerouter from "./components/Iqoption/header/Headerouter";

import Login from "./components/Iqoption/Login";
import Signup from "./components/Iqoption/Signup";
import ForgotPassword from "./components/ForgotPassword";

import Tradersroom from "./components/Iqoption/Tradersroom";
import { logout } from "./slices/auth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Lostpassword from "./components/Iqoption/Lostpassword";
import PersonalData from "./components/Iqoption/Profile/PersonalData";
import { MyProvider } from "./components/Iqoption/Context";
import Withdrawl from "./components/Iqoption/Withdrawl";
import BalanceHistroy from "./components/Iqoption/BalanceHistroy";
import TradingHistory from "./components/Iqoption/TradingHistory";
import AccountVerification from "./components/Iqoption/Profile/AccountVerification";
import DepositHistory from "./components/Iqoption/DepositHistory";
import Home from "./components/Iqoption/HomePageComponent";
import Settings from "./components/Iqoption/Settings";
import Contact from "./components/Iqoption/Contact";
import UpdatePassword from "./components/UpdatePassword";
import VerifyAccount from "./components/VerifyAccount";


const AppContent = () => {
  const location = useLocation(); // Get the current location
  const { user: currentUser } = useSelector((state) => state.auth);
  const [backgroundColor, setBackgroundColor] = useState("#f0f0f0");

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {

    if (location.pathname === "/dashboard") {
      setBackgroundColor("rgb(25, 31, 45)"); // Black background for /dashboard
    } else {
      setBackgroundColor("#f0f0f0"); // Default background for other routes
    }


    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut, location.pathname]);

  return (
    <div className="rvc-utility-app" style={{ backgroundColor }}>
      <div>
        <Toaster position="bottom-center" reverseOrder={true} />
      </div>
      <div className="utility-sub-sec">
        {isLoggedIn ? <Header /> : <Headerouter />}
        <div className="container-fluid px-0">
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/login" element={isLoggedIn ? (<Navigate to="/dashboard" replace />) : (<Login />)} />
            <Route path="/sign-up" element={isLoggedIn ? (<Navigate to="/dashboard" replace />) : (<Signup />)} />
            <Route path="/forgot-password" element={isLoggedIn ? (<Navigate to="/dashboard" replace />) : (<ForgotPassword />)} />
            <Route path="/dashboard" element={!isLoggedIn ? (<Navigate to="/login" replace />) : (<Tradersroom />)} />
            <Route path="/DepositHistory" element={!isLoggedIn ? (<Navigate to="/login" replace />) : (<DepositHistory />)} />
            <Route path="/Settings" element={!isLoggedIn ? (<Navigate to="/login" replace />) : (<Settings />)} />
            <Route path="/profile/personal" element={<PersonalData />}></Route>
            <Route path="/withdrawl" element={<Withdrawl />}></Route>
            <Route path="/transactions" element={<BalanceHistroy />}></Route>
            <Route path="/tradinghistory" element={<TradingHistory />}></Route>
            <Route path="/settings" element={<Settings />}></Route>
            <Route path="/contact-support" element={<Contact />}></Route>
            <Route path="/profile/personal/verification" element={<AccountVerification />}></Route>

            <Route path="/verify-account" element={<VerifyAccount />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/update-password" element={<UpdatePassword />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const App = () => (
  <MyProvider>
    <GoogleOAuthProvider clientId="800895276772-cdd2f1r6gu9pldl8u31rmbjg4g43gt6g.apps.googleusercontent.com">
      <Router>
        <AppContent />
      </Router>
    </GoogleOAuthProvider>
  </MyProvider>
);


export default App;
