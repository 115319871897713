import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
  "auth/register",
  async ({ email, password, country }, thunkAPI) => {
    try {
      const response = await AuthService.register(email, password, country);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password);
      console.log('thisd d d',data);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async ({ email, name, imageUrl }, thunkAPI) => {
    try {
      const data = await AuthService.googleLogin({ email, name, imageUrl });

      console.log('google login data ==>', data);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email }, thunkAPI) => {
    try {
      const data = await AuthService.forgotPassword(email);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});


export const updateBalance = createAsyncThunk(
  'auth/updateBalance',
  async (newBalance) => {
    // Perform any necessary API call here if needed
    return newBalance; // Just return the new balance for now
  }
);

export const updateActiveCoinId = createAsyncThunk(
  'auth/updateActiveCoinId',
  async (newId) => {
    // Perform any necessary API call here if needed
    return newId; // Just return the new balance for now
  }
);




const initialState = user
  ? { 
    isLoggedIn: true, 
    user, 
    balanceAmount: user?.activeSession === 0 ? user?.pacticeBalance : user?.balance,
    activeCoinId:'6708ef77652a6be8b13b34a1' 
  }
  : { isLoggedIn: false, user: null, balanceAmount:0, activeCoinId:'6708ef77652a6be8b13b34a1'  };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.balanceAmount = action.payload.user.activeSession === 0 
        ? action.payload.user.pacticeBalance 
        : action.payload.user.balance;
    },
    [googleLogin.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.balanceAmount = action.payload.user.activeSession === 0 
        ? action.payload.user.pacticeBalance 
        : action.payload.user.balance;
    },
    [googleLogin.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.balanceAmount = 0;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.isForgotPassword = true;
      state.forgotEmail = action.payload.email;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.isForgotPassword = false;
      state.forgotEmail = null;
    },

    // Add custom action for updating balance after bet
    [updateBalance.fulfilled]: (state, action) => {
      state.balanceAmount = action.payload;
    },

    [updateActiveCoinId.fulfilled]: (state, action) => {
      state.activeCoinId = action.payload;
    },
  },
});



const { reducer } = authSlice;
export default reducer;
