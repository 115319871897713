import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faCaretUp,
  faChartColumn,
  faClockRotateLeft,
  faEllipsis,
  faFire,
  faGraduationCap,
  faMedal,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import TradingGraph from "./TradingGraph";
import TradingViewWidget from "./TradingViewWidget";
import DepositHistory from "./DepositHistory";
import Settings from "./Settings";
import Offcanvas from "./Offcanvas/Offcanvas";
import LeftSidebar from "./LeftSidebar";

const Tradersroom = () => {
  const [isActive, setIsActive] = useState(false);
  const [type, setType] = useState(1);
  const handleClick = () => {
    setIsActive((prev) => !prev); // Toggle the active state
  };

  const Leftmenulist = [
    // {icon:<FontAwesomeIcon icon={faBagShopping} />,text:"Total Portfolio"},
    {
      icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
      text: "Trading History",
      type: 0
    },
    { icon: <FontAwesomeIcon icon={faMessage} />, text: "Chat & Support", type: 1 },
    { icon: <FontAwesomeIcon icon={faMedal} />, text: "Leader Board", type: 2 },
    // { icon: <FontAwesomeIcon icon={faFire} />, text: "Promo", type: 3 },
    // { icon: <FontAwesomeIcon icon={faChartColumn} />, text: "Market Analysis", type: 4 },
    // { icon: <FontAwesomeIcon icon={faGraduationCap} />, text: "Tutorials", type: 5 },
    { icon: <FontAwesomeIcon icon={faEllipsis} />, text: "More", type: 4 },
  ];

  const handleLeftMenuItems = (types) => {
    setType(types)
  }

  return (
    <>
      <div className="main main_bg" style={{ minHeight: "calc(100vh - 80px)" }}>
        <div className="container-fluid px-0" style={{ background: "rgb(25 31 45)" }}>
          <div className="row flex-sm-row flex-column-reverse">
            <div
              className=" leftside_bar py-3 text-white"
              style={{ backgroundColor: "rgb(43 53 76)" }}
            >
              <div className="listmenu listmenu  d-flex flex-sm-column flex-row justify-content-sm-start justify-content-between " style={{}}>
                
                {Leftmenulist.map((listitem) => {
                  return (
                    <li className="list-unstyled text-center mb-2">
                      <Link
                        className="text-white text-decoration-none btn"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasExample"
                        aria-controls="offcanvasExample"
                        onClick={() => handleLeftMenuItems(listitem.type)}
                      >
                        <div className="fs-5">{listitem.icon}</div>
                        <div className="fs12">{listitem.text}</div>
                      </Link>
                    </li>
                  );
                })}
              </div>

              <Offcanvas value={type}/>
            </div>
            <div className="trading_graph px-0 position-relative">
              <TradingGraph />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tradersroom;
