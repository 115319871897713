import React, { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBalance } from "../../slices/auth";
import { Navigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TradingGraph.css";
import { faCircleQuestion, faChartLine, faDownLong } from "@fortawesome/free-solid-svg-icons";
import Valuechanger from "./Valuechanger";
import axios from "axios";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { get_user_single_data } from "../../common/Api";

// Import additional required modules for Stock Tools
import StockTools from 'highcharts/modules/stock-tools';
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import DragPanes from 'highcharts/modules/drag-panes';
import Indicators from 'highcharts/indicators/indicators-all';
import FullScreen from 'highcharts/modules/full-screen';
import toast, { Toaster } from 'react-hot-toast';
import NonExpiredBetsTable from './NonExpiredBetsTable'; // Adjust the path as needed


// Initialize the modules
StockTools(Highcharts);
AnnotationsAdvanced(Highcharts);
DragPanes(Highcharts);
Indicators(Highcharts);
FullScreen(Highcharts);

const TradingGraph = () => {

  const [currentValue, setCurrentValue] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isActive, setisActive] = useState(false);
  const [timeMinusThirty, setTimeMinusThirty] = useState(null);
  const chartRef = useRef(null); // Store the chart instance
  const __activeCoinId = localStorage.getItem("activeCoinId");
  const __activeCoinName = localStorage.getItem("activeCoinName");

  const addClass = () => {
    setisActive(!isActive);
  };
  const removeClass = () => {
    setisActive(false);
  };

  const { user: currentUser, balanceAmount, activeCoinId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [currentCoin, setCurrentCoin] = useState("6708ef77652a6be8b13b34a1");
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  const [thresholds, setThresholds] = useState([]);
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);
  const [activeSession, setActiveSession] = useState(0);
  const token = JSON.parse(localStorage.getItem("token"))

  const [coinName, setCoinName] = useState('Bitcoin');
  const [expiredBets, setExpiredBets] = useState([]);
  const [betsChecked, setBetsChecked] = useState(false);


  const fetchNonExpiredBets = async () => {
    try {
      const response = await axios.get(`${apiUrl}/price/fetch_non_expired_bets`, {
        params: { userId: currentUser._id }
      });

      if (response.data.status) {
        const betss = response.data.data.allBets;


        if (betss.length > 0) {
          betss.forEach((bet) => {

          });
        }


        console.log('check bet run time ==> ', 1);

      }
    } catch (error) {
      console.error('Error fetching bets:', error);
    }
  };


  const getSingleUserData = async () => {
    const data = await get_user_single_data(currentUser._id, token)
    setBalance(data?.data?.activeSession === 0 ? data?.data?.practiceBalance : data?.data?.balance);
    //dispatch(updateBalance(data?.data?.activeSession === 0 ? data?.data?.practiceBalance : data?.data?.balance));
    setActiveSession(data?.data?.activeSession);
  }

 
  const chartOptions = {
    chart: {
      renderTo: 'container', // Render to the container
      animation: {
        duration: 800, // Increase animation duration for smoothness
        easing: 'easeOutExpo', // Smooth easing function
      },
    },
    title: {
      text: `${__activeCoinName} Price`,
    },
    xAxis: {
      timezone: 'Asia/Kolkata',
      minRange: 10,
      crosshair: {
        className: 'highcharts-crosshair-custom',
        enabled: true,
      },
      labels: {
        format: '{value:%H:%M:%S}', // Format labels as YYYY-MM-DD HH:MM:SS
      },
      type: 'datetime'
    },
    yAxis: [
      {
        title: {
          text: 'price (USD)',
        },
        plotLines: [],
        crosshair: {
          snap: false,
          className: 'highcharts-crosshair-custom',
          label: {
            className: 'highcharts-crosshair-custom-label',
            enabled: true,
            format: '{value:.2f}',
          },
          zIndex: 5,
          enabled: true,
        },
        labels: {
          align: 'left',
        },
        height: '70%',
      },
      {
        title: {
          text: 'volume',
        },
        crosshair: {
          className: 'highcharts-crosshair-custom',
          snap: false,
          enabled: true,
          label: {
            format:
              '{#if value ge 1000000} {(divide value 1000000):.2f} ' +
              'M {else} {value} {/if}',
            className: 'highcharts-crosshair-custom-label',
            enabled: true,
          },
        },
        labels: {
          align: 'left',
        },
        top: '70%',
        height: '30%',
        offset: 0,
      },
    ],
    series: [
      {
        pointStart: Date.now(),  // Current time in milliseconds
        pointInterval: 5 * 1000, // 30 seconds
        type: 'candlestick',
        id: 'bitcoin-ohlc',
        name: `${__activeCoinName} Price`,
        turboThreshold: 5000,
        lastPrice: {
          enabled: true,
          label: {
            enabled: true,
            backgroundColor: "#FF7F7F",
          },
        },
        data: [],
      },
      {
        pointStart: Date.now(),  // Current time in milliseconds
        pointInterval: 5 * 1000, // 30 seconds
        type: 'column',
        turboThreshold: 5000,
        keys: ['x', 'y', 'className'],
        id: 'bitcoin-volume',
        name: `${__activeCoinName} Volume`,
        data: [],
        yAxis: 1,
      },
    ],
    tooltip: {
      split: true,
      shared: true,
    },
    plotOptions: {
      series: {
        animation: {
          duration: 800, // Smoother transitions
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            var isLast = false;
            if (this.point.x === this.series.data[this.series.data.length - 1].x && this.point.y === this.series.data[this.series.data.length - 1].y) {
              isLast = true;
            }

            // Set color based on whether it's the last point
            const color = isLast ? '#00ff7e' : '#db4931'; // Green for last point, red for others

            // Return the last point's value with the specified color
            return isLast ? `<span style="color: ${color};">${this.y}</span>` : '';
          },
          style: {
            fontSize: '14px', // Set font size larger
            fontWeight: 'bold', // Set font weight to bold
            textOutline: '1px contrast(1)', // Add outline for better visibility
          },
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, 'rgba(255, 255, 255, 0.8)'], // Top color
              [1, 'rgba(0, 0, 0, 0.1)']        // Bottom color
            ]
          },
          borderColor: '#00ff7e', // Border color
          borderWidth: 1, // Border width
          borderRadius: 5, // Rounded corners
        }
      },
    },
    rangeSelector: {
      buttons: [{
        type: 'millisecond',
        count: 2 * 60 * 1000, // 2 minutes in milliseconds
        text: '2 m'
      }, {
        type: 'millisecond',
        count: 5 * 60 * 1000, // 5 minutes in milliseconds
        text: '5 m'
      }, {
        type: 'millisecond',
        count: 15 * 60 * 1000, // 15 minutes in milliseconds
        text: '15 m'
      }, {
        type: 'millisecond',
        count: 30 * 60 * 1000, // 30 minutes in milliseconds
        text: '30 m'
      }, {
        type: 'millisecond',
        count: 60 * 60 * 1000, // 1 hour in milliseconds
        text: '1 h'
      }, {
        type: 'millisecond',
        count: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
        text: '3 h'
      }]
    },

  };


  useEffect(() => {

    // Initialize the chart and store the reference
    chartRef.current = Highcharts.stockChart('container', chartOptions);
    const chart = chartRef.current;

    // Fetch Bitcoin data and predictions
    const fetchBitcoinValue = async (duration) => {
      try {
        const response = await axios.get(`${apiUrl}/price/bitcoin?duration=${duration}&coinId=${__activeCoinId}`);
        const prices = response.data; // Access the actual data from the response

        const ohlc = [];
        const volume = [];
        const dataLength = prices.length;
        const splitIndex = Math.floor(dataLength * 0.7); // 60% actual, 40% predicted

        for (let i = 0; i < dataLength; i += 1) {
          const [timestamp, open, high, low, close, vol] = prices[i]; // Destructure each price entry

          // If all prices are the same, create variations
          const variationAmount = 10; // Maximum variation range

          // Create small variations to generate meaningful OHLC values
          const adjustedOpen = open; // Keep open price as is
          const adjustedClose = adjustedOpen + (Math.random() * variationAmount * (Math.random() < 0.5 ? -1 : 1)); // Randomly adjust close

          // Ensure high and low values are set based on adjustedOpen and adjustedClose
          const adjustedHigh = Math.max(adjustedOpen, adjustedClose) + (Math.random() * variationAmount); // High is above both open and close
          const adjustedLow = Math.min(adjustedOpen, adjustedClose) - (Math.random() * variationAmount); // Low is below both open and close

          const adjustedVolume = vol + Math.random() * 100; // Adjust volume slightly
          
          setBitcoinPrice(close);
          //setCurrentValue(adjustedClose);

          // Push the adjusted or original OHLC data to the ohlc array
          ohlc.push([timestamp, adjustedOpen, adjustedHigh, adjustedLow, adjustedClose]);

          // Keep the original or slightly modified volume data
          volume.push(
            [timestamp, vol + Math.random() * 100, adjustedOpen < adjustedClose ? 'highcharts-point-up' : 'highcharts-point-down']
          ); // Optional adjustment to volume

        }

        // Update the existing series with the fetched and predicted data
        if (chart) {

          const ohlcSeries = chart.get('bitcoin-ohlc');

          if (ohlcSeries) {
            const data = ohlcSeries.options.data;

            if(data.length > 0){
              const lastPoint = data[data.length - 1];

              ohlc.forEach((dataPoint) => {

                if (lastPoint[0] !== dataPoint[0]) {
                  ohlcSeries.addPoint(dataPoint, false); // Add new point if the x-value is different
                } else {
                  data[data.length - 1] = dataPoint; // Update existing point
                  ohlcSeries.setData(data); // Set updated data
                }
              });
            }else{
              ohlc.forEach((dataPoint) => {
                ohlcSeries.addPoint(dataPoint, false); // Add each data point individually
              });
            }
           
          }

          // const volumeSeries = chart.get('bitcoin-volume');

          // if (volumeSeries) {
          //   const volumeData = volumeSeries.options.data;
          //   const lastVolumePoint = volumeData[volumeData.length - 1];

          //   volume.forEach((volumePoint) => {
          //     if (lastVolumePoint[0] !== volumePoint[0]) {
          //       volumeSeries.addPoint(volumePoint, false); // Add new volume point if the x-value is different
          //     } else {
          //       volumeData[volumeData.length - 1] = volumePoint; // Update existing volume point
          //       volumeSeries.setData(volumeData); // Set updated volume data
          //     }
          //   });
          // }


          // const ohlcSeries = chart.get('bitcoin-ohlc');
          // if (ohlcSeries) {
            // ohlc.forEach((dataPoint) => {
            //   ohlcSeries.addPoint(dataPoint, false); // Add each data point individually
            // });

          // }

          const volumeSeries = chart.get('bitcoin-volume');
          if (volumeSeries) {
            volume.forEach((volumePoint) => {
              volumeSeries.addPoint(volumePoint, false); // Add each volume data point individually
            });
          }

          chart.redraw(); // Redraw the chart with the new data

          fetchNonExpiredBets();
        }
      } catch (err) {
        setError('Error fetching Bitcoin data');
      }
    };

    getSingleUserData();

    // Fetch initially with a duration of 15 seconds
    fetchBitcoinValue(60);

    // Set interval to fetch data every 2 seconds
    const interval = setInterval(() => {
      fetchBitcoinValue(1); // Update data every 2 seconds
    }, 200);

    return () => clearInterval(interval); // Clean up on component unmount
  }, []);

  const [amount, setAmount] = useState(1);
  const [expiration, setExpiration] = useState(1);

  // Common function to handle placing a bet
  const placeBet = async (betType) => {
    if (balance < amount) {
      return toast.error('Insufficient Balance! Please First Make Deposit');
    }

    console.log('bitcoinPrice => ', bitcoinPrice);

    const dataToSend = {
      coinId: __activeCoinId,
      userId: currentUser._id,
      betAmount: amount,
      expiration: expiration,
      bitcoinPrice: bitcoinPrice,
      betType, // Pass "high" or "low" as a parameter
    };

    try {
      const response = await axios.post(`${apiUrl}/bet/place-bet`, dataToSend);

      const direction = betType == "high" ? "up" : "down"; // Handle direction based on betType

      const newThreshold = { value: bitcoinPrice, direction };

      // Add plotLine directly to the chart
      if (chartRef.current) {
        const lineColor = newThreshold.direction === 'up' ? '#00ff7e' : '#db4931';
        const className = newThreshold.direction === 'up' ? 'highcharts-point-up' : 'highcharts-point-down';
        console.log('rrrr start',);
        chartRef.current.yAxis[0].addPlotLine({
          color: lineColor,
          className: className,// Color based on direction
          value: newThreshold.value,
          width: 2,
          dashStyle: 'dash',
          label: {
            text: `Bet: $ ${newThreshold.value}`, // Label for the threshold
            align: 'right',
            verticalAlign: 'bottom',
            style: {
              color: lineColor,
            },
          },
          zIndex: 7, // Ensure it's above other plot lines
        });
        console.log('rrrr end', newThreshold.direction === 'up' ? '#00ff7e' : '#db4931');
      }

      const { current_balance } = response.data;
      dispatch(updateBalance(current_balance));

      toast.dismiss();
      toast.success('Bet Placed Successfully.');
    } catch (error) {
      console.log('place bet error ', error);
      toast.dismiss();
      toast.error('Failed to place bet.');
    }
  };

  // Specific functions for higher and lower clicks
  const onHigherClick = () => placeBet("high");
  const onLowerClick = () => placeBet("low");

  if (!currentUser) {
    return <Navigate to="/login" />;
  }


  return (
    <>
      <div className="container-fluid graph_ar px-0">
        <div className="row ">
          <div className="col-xxl-11 col-lg-10 col-sm-9 col-12 px-0 trading_graph_wrapper">
            <div className="tradinggraph" >

              <div id="container" className="chart">
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={chartOptions}
                />
              </div>

            </div>
          </div>
          <div className="col-xxl-1 col-lg-2  col-sm-3  col-12 px-2 rightsidebar_wrapper">
            <div className="rightside_bar position-relative py-2">
              <div
                className={`popup br4 overflow-hidden  ${isActive ? "scale_in d-block" : "d-none"
                  }`}
              >
                <div className="popupheader p-2 px-3 text-white fw-bold">
                  Close Trade At Profit
                </div>
                <div className="popupbody p-3">
                  <Valuechanger lable={"Points"} className="" />
                  <Valuechanger lable={"Profit in Money"} className="" />
                  <div className="popupbtns d-flex align-items-center gap-2">
                    <button
                      className="btn w-50 btn-nm cancel_btn text_slate fs14 text-center br4 mt-2"
                      onClick={removeClass}
                    >
                      Cancel
                    </button>
                    <button className="btn w-50 btn-nm apply_btn text_slate fs14 text-center br4 mt-2">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-sm-block d-flex gap-1">
                <Valuechanger lable={"Amount"} onValueChange={(value) => setAmount(value)} />
                <Valuechanger lable={"Expiration"} onValueChange={(value) => setExpiration(value)} />
              </div>
              <div className="point_value mb-2 text-center">
                <div className=" d-flex align-items-center text-center gap-2 fs-6 py-2 text-white">
                  Profit{" "}
                  <FontAwesomeIcon icon={faCircleQuestion} className="" />
                </div>
                <div className="text_green fs-2 cprfont">+85%</div>
                <div className="text_green fw-semibold fs16 cprfont d-none">$4.10</div>
              </div>
              <div className="d-sm-block d-flex align-items-center gap-1 pnl_btns">
                <button className="btn_bulky btn bg_green text-white rounded mb-sm-2" onClick={onHigherClick}>
                  <FontAwesomeIcon icon={faChartLine} /><br />
                  Higher
                </button>

                <button className="btn_bulky btn bg_red text-white rounded mb-sm-2 " onClick={onLowerClick}>
                  <FontAwesomeIcon icon={faDownLong} /><br />
                  Lower
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <div class="accordion" id="margin_accord">
              <div class="accordion-item bgelem">
                <nav
                  className=""
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div class="nav nav-tabs " id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="nav-margin-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-margin"
                      type="button"
                      role="tab"
                      aria-controls="nav-margin"
                      aria-selected="true"
                    >Options
                    </button>
                  </div>
                </nav>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#margin_accord"
                >
                  <div class="accordion-body ">
                    <div class="tab-content" id="nav-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="nav-margin"
                        role="tabpanel"
                        aria-labelledby="nav-margin-tab"
                        tabindex="0"
                      >
                        <NonExpiredBetsTable />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradingGraph;
