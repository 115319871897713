// Context.js
import React, { createContext, useState } from 'react';

export const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [value, setValue] = useState(false);
  const [active, setactive] = useState(false);

  const updateValue = (newValue) => {
    setValue(newValue);
  };

  const toggleClass = (newValue) =>{
    setactive(newValue);
  }

  return (
    <MyContext.Provider value={{ value, updateValue , active , toggleClass }}>
      {children}
    </MyContext.Provider>
  );
};
