import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form as BootstrapForm } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import mastercard from "../../images/iq/mastercard.svg";
import perfectmoney from "../../images/iq/perfect_money.svg";
import volet from "../../images/iq/volet.svg";
import skrill from "../../images/iq/skrill.svg";
import globe from "../../images/iq/globe_pay_inc.svg";
import upi from "../../images/iq/upi.svg";
import webt from "../../images/iq/webmoney-wmz.svg";
import visa from "../../images/iq/visa.svg";
import btransfer from "../../images/iq/bank_transfer_india.svg";
import { Country } from "country-state-city";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select'
import LoginFooter from "./LoginFooter";
import toast from 'react-hot-toast';
import { register, googleLogin } from "../../slices/auth"
import { clearMessage } from "../../slices/message";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from "swiper/modules";
import { useGoogleLogin } from '@react-oauth/google';
import GatewaySlider from "./GatewaySlider";


const SignupSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  termsAccepted: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required("Required"),
});


const Signup = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const googleLoginAction = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Fetch user information from Google using the access token
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });

        if (!userInfoResponse.ok) {
          throw new Error('Failed to fetch user information');
        }

        const userData = await userInfoResponse.json();

        const { email, name, picture } = userData;

        dispatch(googleLogin({ email, name, picture }))
          .unwrap()
          .then(() => {
            toast.success(`Welcome, ${email}!`);
            navigate("/dashboard");

          })
          .catch(() => {
            setLoading(false);
          });


      } catch (error) {
        toast.error('Sign-up failed, please try again.');

      }
    },
    onError: (error) => {
      toast.error('Sign-up failed, please try again.');
    },
  });

  const [successful, setSuccessful] = useState(false);

  const [formShow, setFormShow] = useState(false);
  const [buttonShow, setButtonShow] = useState(true);

  // Fetch countries using Country-state-city
  const countryOptions = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);


  const handleRegister = (formValue, { resetForm }) => {
    const { email, password, country } = formValue;

    setSuccessful(false);

    dispatch(register({ email, password, country }))
      .unwrap()
      .then(() => {
        toast.success('Your account has been created Successfully.');
        setSuccessful(true);
        resetForm();  // Reset the form here
        dispatch(clearMessage());
      })
      .catch((error) => {
        // Check if error.message exists; otherwise, provide a fallback
        const errorMessage = error.message || "Registration failed. Please try again.";
        toast.error(errorMessage);
        setSuccessful(false);
        dispatch(clearMessage());
      });
  };


  return (
    <>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-8 col-sm-12 col-12 mx-auto">
          <div className="card p-4">

            <div className="text-center formheading fs-2 fw-bold my-3">
              Sign Up
            </div>

            <Formik
              initialValues={{
                country: "",
                email: "",
                password: "",
                termsAccepted: false,
              }}
              validationSchema={SignupSchema}
              onSubmit={handleRegister}
            >
              {({ handleSubmit, setFieldValue, values }) => (
                <Form onSubmit={handleSubmit}>
                  {/* Social Login Links */}
                  {/* <div className="py-3 mb-3 hollow-anchor">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-2 text-decoration-none justify-content-center text-dark"
                    >
                      <FontAwesomeIcon
                        style={{
                          width: "25px",
                          height: "25px",
                          color: "rgb(0 57 141)",
                        }}
                        icon={faFacebook}
                      />
                      Facebook
                    </Link>
                  </div> */}

                  <div className="py-3 mb-3 hollow-anchor">
                    <Link
                      to="#"
                      onClick={() => googleLoginAction()}
                      className="d-flex align-items-center gap-2 text-decoration-none justify-content-center text-dark"
                    >
                      {/* Google Icon */}
                      <svg
                        width={"25px"}
                        height={"25px"}
                        xmlns="http://www.w3.org/2000/svg"
                        xmlSpace="preserve"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
                          style={{ fill: "#fbbb00" }}
                        />
                        <path
                          d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
                          style={{ fill: "#518ef8" }}
                        />
                      </svg>
                      Google
                    </Link>
                  </div>

                  {/* OR separator */}
                  <div className="text-center mb-3">or</div>

                  {/* Email Sign Up Button */}
                  <button
                    onClick={() => {
                      setFormShow(!formShow);
                      setButtonShow(false);
                    }}
                    className={`w-100 p-3 hollow_btn mb-3 position-relative d-flex align-items-center justify-content-center gap-2 ${buttonShow ? "d-block" : "d-none"
                      }`}
                  >
                    <div>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    Sign up With Email
                  </button>

                  {/* Signup Form */}
                  <div className={`signupwithemail ${formShow ? "d-block" : "d-none"}`}>
                    {/* Country Field */}
                    <BootstrapForm.Group as={Col} className="mb-3">
                      <Col sm="12">
                        <Select
                          name="country"
                          options={countryOptions}
                          onChange={(option) => setFieldValue("country", option.value)}
                          className=" select-custom"  // Added form-control class
                          classNamePrefix="select"
                        />

                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-danger "
                        />
                      </Col>
                    </BootstrapForm.Group>

                    {/* Email/Phone Field */}
                    <BootstrapForm.Group as={Col} className="mb-3">
                      <Col sm="12">
                        <Field
                          className="p-3 form-control"
                          type="text"
                          name="email"
                          placeholder="Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </BootstrapForm.Group>

                    {/* Password Field */}
                    <BootstrapForm.Group as={Col} className="mb-3">
                      <Col sm="12">
                        <Field
                          className="p-3 form-control"
                          type="password"
                          name="password"
                          autoComplete="new-password"
                          placeholder="Password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </BootstrapForm.Group>

                    {/* Terms and Conditions Checkbox */}
                    <BootstrapForm.Group className=" mb-3">
                    <div className="d-flex align-items-start">

                      <Field
                        type="checkbox"
                        name="termsAccepted"
                        id="termsAccepted"
                        className="me-2 mt-1"
                      />
                      <label htmlFor="termsAccepted" className="fs16">
                        I confirm that I am 18 years old or older and accept the{" "}
                        <Link to="/terms-and-conditions" className="text_orng">Terms & Conditions</Link>,{" "}
                        <Link to="/privacy-policy" className="text_orng">Privacy Policy</Link>, and{" "}
                        <Link to="/order-execution-policy" className="text_orng">
                          Order Execution Policy
                        </Link>
                        .
                      </label>
                    </div>
                      <ErrorMessage
                        name="termsAccepted"
                        component="div"
                        className="text-danger"
                      />
                    </BootstrapForm.Group>


                    {/* Submit Button */}
                    <button
                      type="submit"
                      className="w-100 p-3 bg_green border-0 fw-bold mb-3 btn btn-primary"
                    >
                      Continue with Email
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="text-center mb-2"></div>
            <div className="text-center fs16 mb-3">
              Already have an account?
              <Link
                to={"/login"}
                className="text_orng fs16 text-decoration-none ms-1"
              >
                Login
              </Link>
              <span className="ms-1">now</span>
            </div>
          </div>
            {/* <div className="riskwarning border position-relative py-3 px-5 mt-4">
              <span className="riskwarning_text fw-bold text_slatedk position-absolute start-50 top-0 translate-middle p-2 bg-white ">
                Risk Warning:
              </span>
              <span className="text_slate fs14 position-relative z-1">
                All trading involves risk. Only risk capital you're prepared
                to lose.
              </span>
            </div> */}
          </div>
        </div>
        {/* <GatewaySlider/> */}
        <LoginFooter />
      </div>
    </>
  );
};

export default Signup;
