import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { css } from "../../common/Api";



const Contact = ({ st }) => {

  const [loading, setLoading] = useState(true);

    useEffect(() => {
        const cssFiles = st ? [
            `${css}/css/style.css`,
            `${css}/css/owl.css`,
            // `${css}/css/bootstrap.css`,
            `${css}/css/animate.css`,
            `${css}/css/style.css`,
            `${css}/css/custom.css`,
            `${css}/css/responsive.css`,
            `${css}/css/frontend-lite.min.css`,
            `${css}/css/post-10.css`,
        ] : [];

        const promises = cssFiles.map((cssFile) => {
            return new Promise((resolve) => {
                const link = document.createElement('link');
                link.rel = 'stylesheet';
                link.href = cssFile;
                link.onload = () => resolve();
                document.head.appendChild(link);
            });
        });

        Promise.all(promises).then(() => {
            setLoading(false); // All CSS files loaded
        });

        return () => {
            // Cleanup old links if necessary
        };
    }, [st]);

    if (loading) {
        return <div class="spinner-box">
        <div class="circle-border">
          <div class="circle-core"></div>
        </div>  
      </div>; // Or a skeleton component
    }

  return (
    <>
      <Container fluid>
      <Row>
      <Col sm={10} className="mx-auto">
      <section className="contact-section alternat-2 sec-pad centred" style={{    background: "#f0f0f0"}}>
  <div className="auto-container">
    <div className="sec-title">
      <span className="sub-title">Drop a Line</span>{" "}
      <h2>
        Send Your <span>Message</span> to us
      </h2>{" "}
    </div>
    <div id="contact-form">
      <div className="wpcf7 js" id="wpcf7-f467-p55-o1" lang="en-US" dir="ltr">
        <div className="screen-reader-response">
          <p role="status" aria-live="polite" aria-atomic="true" /> <ul />
        </div>
        <form
          action="#"
          method="post"
          className="wpcf7-form init"
          aria-label="Contact form"
          noValidate="novalidate"
          data-status="init"
        >
          <div style={{ display: "none" }}>
            <input type="hidden" name="_wpcf7" defaultValue={467} />
            <input type="hidden" name="_wpcf7_version" defaultValue="5.7.6" />
            <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" />
            <input
              type="hidden"
              name="_wpcf7_unit_tag"
              defaultValue="wpcf7-f467-p55-o1"
            />
            <input
              type="hidden"
              name="_wpcf7_container_post"
              defaultValue={55}
            />
            <input
              type="hidden"
              name="_wpcf7_posted_data_hash"
              defaultValue=""
            />
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 left-column">
              <div className="left-content">
                <div className="form-group">
                  <p>
                    <span
                      className="wpcf7-form-control-wrap"
                      data-name="text-718"
                    >
                      <input
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Your Name"
                        defaultValue=""
                        type="text"
                        name="text-718"
                      />
                    </span>
                  </p>
                </div>
                <div className="form-group">
                  <p>
                    <span
                      className="wpcf7-form-control-wrap"
                      data-name="email-146"
                    >
                      <input
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Email Address"
                        defaultValue=""
                        type="email"
                        name="email-146"
                      />
                    </span>
                  </p>
                </div>
                <div className="form-group">
                  <p>
                    <span
                      className="wpcf7-form-control-wrap"
                      data-name="text-719"
                    >
                      <input
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Phone"
                        defaultValue=""
                        type="text"
                        name="text-719"
                      />
                    </span>
                  </p>
                </div>
                <div className="form-group">
                  <p>
                    <span
                      className="wpcf7-form-control-wrap"
                      data-name="text-720"
                    >
                      <input
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Subject"
                        defaultValue=""
                        type="text"
                        name="text-720"
                      />
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 right-column">
              <div className="right-content">
                <div className="form-group">
                  <p>
                    <span
                      className="wpcf7-form-control-wrap"
                      data-name="textarea-173"
                    >
                      <textarea
                        cols={40}
                        rows={10}
                        className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Write Your Message Here..."
                        name="textarea-173"
                        defaultValue={""}
                      />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
         
          <div className="message-btn my-3">
            <p>
              <button type="submit" className="btngrey text-white py-2 px-5" name="submit-form">
                <span className="px-0">Send Now</span>
              </button>
            </p>
          </div>
          <div className="wpcf7-response-output" aria-hidden="true" />
        </form>
      </div>
    </div>
  </div>
</section>
      </Col>   
      </Row>

      </Container>
    </>
  );
};

export default Contact;
