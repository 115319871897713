import React, { useContext, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoCloseCircle } from "react-icons/io5";
import { MyContext } from './Context';
import GatewaySlider from './GatewaySlider';
import LoginFooter from './LoginFooter';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { get_user_account_request_data } from '../../common/Api';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/auth';

const Settings = () => {
    const { active, toggleClass } = useContext(MyContext); // Destructure active and toggleClass
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [bodymessage, setBodyMessage] = useState('');
    const user = JSON.parse(localStorage.getItem("user"))
    const token = JSON.parse(localStorage.getItem("token"))
    const dispatch = useDispatch()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmitCloseAccount = (messages, bodymessage) => {
        setShow(true)
        setMessage(messages)
        setBodyMessage(bodymessage)
    }

    console.log(token)

    const handleRequestDeleteAccount = async () => {
        try {
           
            const data = await get_user_account_request_data(user._id, token)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    localStorage.clear()
                    dispatch(logout())
                }, 2000)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className={`m-auto row desposit-content  dep_show`}>

                <div className='col-lg-10 mx-auto'>
                    <Row>
                        <div>
                            <h1 className='text-center text_slate mb-sm-5 mb-2'>
                                Account Settings
                            </h1>

                            <Col xxl={8} className='mx-auto settinglist'>
                                <Row className='align-items-center  settinglist_item py-4'>
                                    <Col md={9} sm={8} >
                                        <h6 className='text-uppercase text_slate fs16'>
                                            Temporary Closing of account
                                        </h6>
                                        <p className='text_slate fs14 text-capitalize'>
                                            you can temporarily close you account. Once your account is closed you will not be able to log in or make transactions. you can reopen your account by contacting our support team
                                        </p>
                                    </Col>
                                    <Col md={3} sm={4} className='d-flex justify-content-end'>
                                        <button className='btngrey text-capitalize text-white px-4 py-2 fs14 border-0' onClick={() => handleSubmitCloseAccount('Temporary Closing of account', 'Are you to close you account?')}>
                                            Close account
                                        </button>
                                    </Col>
                                </Row>
                                <Row className='align-items-center  settinglist_item py-4'>
                                    <Col md={9} sm={8}>
                                        <h6 className='text-uppercase text_slate'>
                                            Deletion of account and personal data
                                        </h6>
                                        <p className='text_slate fs14 text-capitalize'>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        </p>
                                        <p className='note fs12 text_orng'>
                                            Note : Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis

                                        </p>
                                    </Col>
                                    <Col md={3} sm={4} className='d-flex justify-content-end'>
                                        <button className='btngrey text-capitalize text-white px-4 py-2 fs14 border-0 ' onClick={() => handleSubmitCloseAccount('Deletion of account and personal data', 'Are you sure to permanent delete you account?')}>
                                            Request
                                        </button>
                                    </Col>
                                </Row>

                            </Col>

                        </div>
                    </Row>

                </div>

            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{message}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}>
                    <p>{bodymessage}</p>
                    <div class="d-flex gap-2" style={{ textAlign: "center", justifyContent: "center" }}>
                        <Button variant="danger" onClick={handleRequestDeleteAccount}>Yes</Button>{' '}
                        <Button variant="warning">No</Button>{' '}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Settings