import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import toast from 'react-hot-toast';
import { get_user_coin_data, get_user_single_data, user_withdrawal_store } from '../../common/Api';
import GatewaySlider from "./GatewaySlider";
import LoginFooter from "./LoginFooter";
import moment from 'moment'


const Withdrawl = () => {

  const [users, setUsers] = useState({})
  const [profile, setProfile] = useState({})
  const [amount, setAmount] = useState('')
  const [withdrawaladdress, setWithdrawalAddress] = useState('')
  const [allwithdrawal, setAllwithdrawal] = useState([])
  const [coin, setCoin] = useState([])
  const user = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  const [selectedCoin, setSelectedCoin] = useState('');

  const handleSelectChange = (event) => {
    setSelectedCoin(event.target.value);
  };

  const user_data = async () => {
    try {
      const data = await get_user_single_data(user._id, token)
      console.log(data)
      setUsers(data.data)
      setProfile(data.profile)
    }
    catch (error) {
      console.log(error)
    }
  }

  const userGetToken = async () => {
    try {
      const data = await get_user_coin_data(user._id, token)
      //console.log(data)
      setCoin(data.coin)
      setAllwithdrawal(data.data)
    }
    catch (error) {
      console.log(error)
    }
  }






  useEffect(() => {
    user_data()
    userGetToken()
  }, [user._id, token])

  const hanldleWithdrawal = async () => {
    if (amount == '') {
      return toast.error('Please Enter Some Amount!')
    }
    if (withdrawaladdress == '') {
      return toast.error('Please Enter Withdrawal Address')
    }
    try {
      if (users?.balance < amount) {
        return toast.error('Insufficent Balance !')
      }

      const submitData = {
        amount: amount,
        address: withdrawaladdress,
        coinId: selectedCoin
      }

      const data = await user_withdrawal_store(user._id, token, submitData)

      if (data.status == true) {
        toast.success(data.message)
        setAmount('')
        setSelectedCoin('')
        setWithdrawalAddress('')
        userGetToken()
        user_data()
      } else {
        toast.error(data.message)
      }

    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <>
      <Container>

        <Row className="py-5">
          <Col lg={11} className="mx-auto">
            <div className='mb-5'>
              <h2 className='text-center'>Withdrawal</h2>
            </div>
            <div className='row'>
              <div className="col-xl-4 col-lg-5 ">
                <div className="col-xl mb-3">
                  <div className="card py-3 px-2">
                    <div className="card-header d-flex justify-content-between align-items-center border-0">
                      <h5 className="mb-0 text_slate">Total Balance : {users?.balance} metabt</h5>
                    </div>
                    <div className="card-body">

                      <div className="mb-3">
                        <div className="mb-1">
                          <label className="form-label text_slate fw-semibold" htmlFor="transfer_amount">
                            Coin
                          </label>
                          <select id="coin" className="form-control" onChange={handleSelectChange} value={selectedCoin}>
                            <option>Select Coin</option>
                            {
                              coin?.map((items, i) => {
                                return (
                                  <option key={i} value={items?._id}>{items?.name}</option>
                                )
                              })
                            }


                          </select>
                        </div>
                        <span id="withdrawal-total" className="text-danger" />
                      </div>

                      <div className="mb-3">
                        <div className="mb-1">
                          <label className="form-label text_slate fw-semibold" htmlFor="transfer_amount">
                            Amount
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="transfer_amount"
                            name="transfer_amount"
                            placeholder="Enter Amount Here"
                            autoComplete="transaction-amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                        <span id="withdrawal-total" className="text-danger" />
                      </div>
                      {/* <div className="mb-3">
                          <label className="form-label text_slate fw-semibold" htmlFor="txn_password">
                            Txn Password
                          </label>
                          <input
                            type="password"
                            id="txn_password"
                            name="txn_password"
                            className="form-control phone-mask"
                            placeholder="Enter Your Txn Password Here"
                            autoComplete="current-password webauthn"
                          />
                        </div> */}
                      <div className="mb-3">
                        <label className="form-label text_slate fw-semibold" htmlFor="address">
                          Withdrawal Address
                        </label>
                        <input
                          readOnly=""
                          disabled=""
                          type="text"
                          className="form-control"
                          id="address"
                          name="address"
                          //defaultValue="0xC1C15FdBfE69Ee0e938e26929901bB1b696b70Ad"
                          placeholder="Enter Your Address Here"
                          value={withdrawaladdress}
                          onChange={(e) => setWithdrawalAddress(e.target.value)}
                        />
                      </div>
                      <button
                        onClick={hanldleWithdrawal}
                        className="btn btngrey text-white w-100 my-3"
                      >
                        Withdraw Now
                      </button>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 order-0 order-md-1">
                <div className="card py-3 px-2 ">
                  <h5 className="card-header text_slate border-0">Withdrawals History</h5>
                  <div className="table-responsive text-nowrap">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Coin</th>
                          <th>Amount</th>
                          <th>Conversion Rate</th>
                          <th>Conversion Amount</th>
                          <th>Address</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          allwithdrawal?.length > 0 ? <>
                            {
                              allwithdrawal?.map((items, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{items?.coinId?.name} </td>
                                    <td>{items?.amount} metabt</td>
                                    <td>$ {items?.conversionrate}</td>
                                    <td>$ {items?.conversionamount}</td>
                                    <td style={{ textWrap: "balance" }}>
                                      {items?.address}
                                    </td>
                                    <td>
                                      {
                                        items?.status == 1 ? (
                                          <h6 className="mb-0 align-items-center d-flex w-px-100 text-success">
                                            <i className="ti ti-circle-filled fs-tiny me-1" />
                                            Completed
                                          </h6>
                                        ) : items?.status == 2 ? (
                                          <h6 className="mb-0 align-items-center d-flex w-px-100 text-danger">
                                            <i className="ti ti-circle-filled fs-tiny me-1" />
                                            Reject
                                          </h6>
                                        ) : (
                                          <h6 className="mb-0 align-items-center d-flex w-px-100 text-warning">
                                            <i className="ti ti-circle-filled fs-tiny me-1" />
                                            Pending
                                          </h6>
                                        )
                                      }

                                    </td>
                                    <td>{moment(items?.createdAt).format('DD MMMM YYYY')}</td>
                                  </tr>
                                )
                              })
                            }
                          </> : <>
                            <tr className=''>
                            
                              <p className='text-center text_slate'>No record found!</p>
                            </tr>
                          </>

                        }


                      </tbody>
                      <tfoot className="table-border-bottom-0">
                        <tr>
                          <th>S.No</th>
                          <th>Coin</th>
                          <th>Amount</th>
                          <th>Conversion Rate</th>
                          <th>Conversion Amount</th>
                          <th>Address</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="row"></div>
                  </div>
                </div>
              </div>
            </div>

          </Col>

        </Row>

      </Container>

    </>
  )
}

export default Withdrawl