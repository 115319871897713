import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import LoginFooter from './LoginFooter';
import { Formik, Field, Form as F2, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login, googleLogin } from "../../slices/auth"
import { clearMessage } from "../../slices/message";

import toast from 'react-hot-toast';
import { useGoogleLogin } from '@react-oauth/google';

const Login = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth.isLoggedIn);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Please enter your phone number or email"),
    password: Yup.string().required("Please enter your password"),
  });

  const handleLogin = (formValue) => {
    const { username, password } = formValue;
    setLoading(true);

    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        toast.success(`Welcome, ${username}!`);
        navigate("/dashboard");
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const googleLoginAction = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Fetch user information from Google using the access token
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });

        if (!userInfoResponse.ok) {
          throw new Error('Failed to fetch user information');
        }

        const userData = await userInfoResponse.json();

        const { email, name, picture } = userData;

        dispatch(googleLogin({ email, name, picture }))
        .unwrap()
        .then(() => {
          toast.success(`Welcome, ${email}!`);
          navigate("/dashboard");
          
        })
        .catch(() => {
          setLoading(false);
        });

      
      } catch (error) {
        toast.error('Sign-up failed, please try again.');
       
      }
    },
    onError: (error) => {
      toast.error('Sign-up failed, please try again.');
    },
  });


  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  if (message) {
    toast.dismiss();
    toast.error(message);
    dispatch(clearMessage());
  }

  return (
    <>
      <div className='container-fluid py-5'>
        <div className='row'>
          <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-8 col-sm-12 col-12 mx-auto'>
          <div className="card p-4">
            <div className='text-center formheading fs-2 fw-bold my-3'>
              Log In
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
              enableReinitialize={false}
            >
              <F2>
                <Form.Group as={Col} className="mb-3" controlId="formPlaintextEmail">
                  <Col sm="12">
                    <Field name="username" type="text" autoComplete="off" className=" p-3 form-control" placeholder="Phone Number or Email" />
                    <ErrorMessage
                      name="username"
                      component="span"
                      className="errorInput"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Col} className="mb-3" controlId="formPlaintextPassword">
                  <Col sm="12">
                    <Field
                      name="password"
                      type='password'
                      autoComplete="new-password"
                      className="form-control p-3"
                      placeholder="Enter Password"
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="errorInput"
                    />
                  </Col>
                </Form.Group>

                <button type="submit" className="text-white w-100 p-3 bg_green border-0 fw-bold mb-3" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>{' '}

                <div className='text-center mb-3 fs16'>or use a social account</div>

                {/* <Button type="button" className='w-100 p-3  border-0 fw-bold mb-3 fb_btn position-relative'>
                  <FontAwesomeIcon className='floating_btn_icon' style={{ width: "25px", height: "25px" }} icon={faFacebook} />
                  Log In With Facebook</Button>{' '} */}

                <button
                  onClick={() => googleLoginAction()}
                  type="button" className='w-100 p-3 hollow_btn fw-bold mb-3 position-relative'>
                  <div className='googleicon'>
                    <svg className='floating_btn_icon' width={"25px"} height={"25px"}
                      xmlns="http://www.w3.org/2000/svg"
                      xmlSpace="preserve"
                      style={{ enableBackground: "new 0 0 512 512", }}
                      viewBox="0 0 512 512">
                      <path d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
                        style={{ fill: "#fbbb00", }}
                      />
                      <path d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
                        style={{ fill: "#518ef8", }}
                      />
                      <path d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
                        style={{ fill: "#28b446", }}
                      />
                      <path
                        d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
                        style={{ fill: "#f14336", }}
                      />
                    </svg>

                  </div>
                  Log In With Google</button>{' '}
                <div className='text-center mb-2'>

                  <Link to={"/forgot-password"} className='text_orng fs16 text-decoration-none '>
                    Forgot Password ?
                  </Link>
                </div>
                <div className='text-center fs16 mb-3'>
                  Don't have an account?
                  <Link to={"/sign-up"} className='text_orng fs16 text-decoration-none ms-1'>
                    Sign Up
                  </Link>
                </div>

                {/* <div className='riskwarning border position-relative py-3 px-5 mt-4'>
                  <span className='riskwarning_text fw-bold text_slatedk position-absolute start-50 top-0 translate-middle p-2 bg-white '>Risk Warning:</span>
                  <span className='text_slate fs14 position-relative z-1'>
                    All trading involves risk. Only risk capital you're prepared to lose.
                  </span>
                </div> */}

              </F2>
            </Formik>
          </div>

          </div>

        </div>

        <LoginFooter />

      </div>
    </>
  )
}

export default Login