import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL+'/';

const register = (email, password, country) => {
  return axios.post(API_URL + "user/register", {
    email: email,
    password: password,
    country: country
  }, { headers: authHeader() });
};


const forgotPassword = (email) => {
  return axios.post(API_URL + "reset-password", {
    email: email
  }, { headers: authHeader() });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "user/user-login", {
      email: username,
      passwords: password
    }, { headers: authHeader() })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", JSON.stringify(response.data.token));
        localStorage.setItem("activeCoinId", '6708ef77652a6be8b13b34a1');
        localStorage.setItem("activeCoinIdIndex",3);
        localStorage.setItem("activeCoinName",'Bitcoin');
      }
      return response.data.data;
    })
};

const googleLogin = ({ email, name, imageUrl }) => {
  return axios
    .post(API_URL + 'user/google-login', {
      email,
      name,
      imageUrl,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", JSON.stringify(response.data.token));
        localStorage.setItem("activeCoinId", '6708ef77652a6be8b13b34a1');
        localStorage.setItem("activeCoinIdIndex",3);
        localStorage.setItem("activeCoinName",'Bitcoin');
      }
      return response.data.data;
    });
};


const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  register,
  login,
  logout,
  forgotPassword,
  googleLogin
};

export default authService;
