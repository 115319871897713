import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { RiSecurePaymentFill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Import Swiper React components

import LoginFooter from './Iqoption/LoginFooter';
import { send_user_forget_mail, update_user_forget_mail } from '../common/Api';
import toast from 'react-hot-toast';

const UpdatePassword = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const getemail = query.get('email');
  const gettoken = query.get('token');

  //console.log(gettoken)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirm, setConfirmPassword] = useState('')

  const hanldeUpdatePassword = async () => {
    try {
      if (password == '' || setConfirmPassword == '') {
        return toast.error('Please Enter Password');
      }

      if (password != confirm) {
        return toast.error('Password and Confirm Password does not match!');
      }


      const submitData = {
        email: getemail,
        token: gettoken,
        password: password
      }
      const data = await update_user_forget_mail(submitData)
      if (data.status == true) {
        toast.success(data.message)
        setInterval(() => {
          navigate('/login')
        }, 2000)
      }

    } catch (error) {

    }
  }


  return (
    <>
      <Container className='pt-5'>
        <Row>
          <div className="css-rx9tub e1imzaze0">
            <div className="css-1aspc4a e1imzaze1">
              <div className='card p-4'>
                <h1 className="css-1ffs9d1 e131aulr0">
                  <span dir="auto">Password recovery</span>
                </h1>
                <div className='text-center'>
                  <RiSecurePaymentFill className='fs-1 text_orng' />
                </div>
                <div className="css-v8v0jn e1pc040f1">
                  <span dir="auto">
                    To proceed with changing your password
                  </span>
                </div>

                <div className="css-1u76601 e1pc040f3">
                  <div className="css-8atqhb efcvym30">
                    <div className="css-1b7kpa egzimz0 mb-4">
                      <input
                        autoComplete="new-password"
                        step="any"
                        placeholder="Password"
                        name="identifier"
                        type="password"
                        className="css-kvxi0i egzimz1"
                        defaultValue=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />

                    </div>
                    <div className="css-1b7kpa egzimz0">
                      <input
                        autoComplete="new-password"
                        step="any"
                        placeholder="Confirm Password"
                        name="identifier"
                        type="password"
                        className="css-kvxi0i egzimz1"
                        defaultValue=""
                        value={confirm}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />

                    </div>
                  </div>
                </div>
                <button
                  className="Button Button_green Button_big Button_fontBig css-120qcw7 e1pc040f4"
                  data-test-id="login-submit-button"
                  onClick={hanldeUpdatePassword}
                >
                  <span dir="auto">Update Password</span>
                </button>

                <div className="css-xi606m e3v9ozq0">
                  <button
                    className="Button Button_simple css-vwowzz e3v9ozq2"
                    data-test-id="back-to-login-button"
                    type="button"
                  >
                    <Link to="/login" dir="auto" className='text_orng text-decoration-none fs14'>Back to Log In</Link>
                  </button>
                  <div data-test-id="auth-link" className="css-gecade e3v9ozq3">
                    <span dir="auto" className='fs14'>
                      Don't have an account?{" "}
                      <Link to="/sign-up" className="link">
                        Sign Up
                      </Link>
                    </span>
                  </div>
                </div>


              </div>
            </div>
          </div>

        </Row>



      </Container>
      <Container fluid>
        {/* <div className="row my-5 border border-start-0 border-end-0">
          <div className="col-lg-8 mx-auto">
            <div className="wallet-slider  py-1">
              <Swiper
                slidesPerView={8}
                spaceBetween={5}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper py-4"
              >
                <SwiperSlide className="text-center">
                  <img src={mastercard} alt="mastercard" />
                </SwiperSlide>
                <SwiperSlide className="text-center">
                  <img src={perfectmoney} alt="perfectmoney" />
                </SwiperSlide>
                <SwiperSlide className="text-center">
                  <img src={skrill} alt="skrill" />
                </SwiperSlide>
                <SwiperSlide className="text-center">
                  <img src={globe} alt="globe" />
                </SwiperSlide>
                <SwiperSlide className="text-center">
                  <img src={volet} alt="volet" />
                </SwiperSlide>
                <SwiperSlide className="text-center">
                  <img src={upi} alt="upi" />
                </SwiperSlide>
                <SwiperSlide className="text-center">
                  <img src={visa} alt="visa" />
                </SwiperSlide>
                <SwiperSlide className="text-center">
                  <img src={btransfer} alt="btransfer" />
                </SwiperSlide>
                <SwiperSlide className="text-center">
                  <img src={webt} alt="webt" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div> */}
        <LoginFooter />
      </Container>
    </>
  );
};

export default UpdatePassword;
