import React from "react";
import { IoMdClose } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { IoMdSend } from "react-icons/io";
import indianflag from "../../../images/iq/indian_flag.svg";

const Promo = () => {
  return (
    <>
    <div class="offcanvas-header">
      <h6
        class="offcanvas-title text-white fw-normal"
        id="offcanvasExampleLabel"
      >
        Promo
      </h6>

      <IoMdClose
        className="fs-4 text-white ms-auto"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>
    <div class="offcanvas-body px-0 position-relative">
      <div className=" h-100 promotabs">
      <ul class="nav nav-pills mb-3 w-100" id="pills-tab" role="tablist">
  <li class="nav-item w-50 text-white" role="presentation">
    <button class="nav-link w-100 active text-white" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
            Available
    </button>
  </li>
  <li class="nav-item w-50 text-white" role="presentation">
    <button class="nav-link w-100 text-white" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
        History
    </button>
  </li>
</ul>
<div class="tab-content h-100 d-flex align-items-center justify-content-center" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0"> 
  <p className="fs14 fw-semibold text_slate text-center w-75 mx-auto">
        You Have No Available Promo For Now
      </p>
  </div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
  <p className="fs14 fw-semibold text_slate text-center w-75 mx-auto">
        You Have No Available Promo For Now
      </p>
  </div>
</div>
      
      </div>

      
    </div>
  </>
  )
}

export default Promo