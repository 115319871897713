import React from 'react'
import Logo from "../../../images/iq/beeta.png";
import { Link } from 'react-router-dom';
import ukflag from '../../../images/uk_flag.svg'
import italyflag from '../../../images/italy_flag.svg'
import indo_flag from '../../../images/indo_flag.svg'
import sflag from '../../../images/s_flag.svg'

const Headerouter = () => {
  return (
    <>
      <header className="py-3 headerouter" style={{background:"rgb(25 31 45)"}}>
        <div className="container-fluid ">
          <div className="row align-items-center gap-3">
            <div className='header_left w-auto mx-sm-0 mx-auto'>
              <Link className="navbar-brand w-auto" to="/">
                <img src={Logo} alt="Logo" width={"125px"} />
              </Link>
            </div>
            <div className="header_right w-auto ms-sm-auto mx-sm-0 mx-auto d-flex align-items-center gap-4">
              <div>
                <div className="dropdown">
                  <Link className=" dropdown-toggle text-white text-decoration-none" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={ukflag} width="20px" alt='' className='me-1' /> En
                  </Link>
                  <ul className="dropdown-menu  " style={{ width: "300px" }}>


                    <div className='row flex-wrap'>
                      {[{ text: "English", flag: ukflag },
                      { text: "Indonesia", flag: indo_flag },
                      { text: "Italiano", flag: italyflag },
                      { text: "Svenska", flag: sflag }].map((country) => {
                        return (
                          <li className='col-6'>
                            <Link to={"#"} className='dropdown-item'>
                              <img src={country.flag} width={"20px"} alt="" className='me-1' /> {country.text}
                            </Link>
                          </li>
                        )
                      })}
                    </div>
                  </ul>
                </div>
              </div>
              <Link to={"/sign-up"} className="btn  btn_orng bg_orng border-0 text-white" type="submit" >Sign Up</Link>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Headerouter