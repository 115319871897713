import React from 'react'
import { Link } from 'react-router-dom'
import ios from "../../images/iq/ios.svg"
import iosimg from "../../images/iq/ios_text.svg"
import google from "../../images/iq/googleplay.svg"
import googleimg from "../../images/iq/googleplay_text.svg"
import fb from "../../images/iq/facebook.svg"
import tele from "../../images/iq/telegram.svg"
import insta from "../../images/iq/instagram.svg"
import twitter from "../../images/iq/twitter.svg"
import youtube from "../../images/iq/youtube.svg"

const LoginFooter = () => {
  return (
    <>
      <div className='row'>
        <div className='col-xxl-7  col-md-9 col-12  mx-auto'>
          <div className='row'>
            {/* <div className='col-9'>
              <div className='riskwarning border position-relative py-3 px-3 mt-4 mb-2'>
                <span className='riskwarning_text fw-bold text_slatedk position-absolute start-25 top-0 translate-middle-y p-2 bg-white '>Risk Warning:</span>
                <span className='text_slate fs14 position-relative z-1'>
                  The Financial Products offered by the company include Contracts for Difference ('CFDs') and other complex financial products. Trading CFDs carries a high level of risk since leverage can work both to your advantage and disadvantage. As a result, CFDs may not be suitable for all investors because it is possible to lose all of your invested capital. You should never invest money that you cannot afford to lose. Before trading in the complex financial products offered, please ensure to understand the risks involved.
                </span>
              </div>
              <div className='fs14 text_slate'>
                You are granted limited non-exclusive non-transferable rights to use the IP provided on this website for personal and non-commercial purposes in relation to the services offered on the Website only.
              </div>
            </div>
            <div className='col-3'>
              <div className='riskwarning border position-relative py-3 px-3 mt-4 mb-2'>
                <span className='riskwarning_text fw-bold text_slatedk position-absolute start-25 top-0 translate-middle-y p-2 bg-white '>Download App</span>
                <span className='text_slatedk fs14 position-relative z-1 fw-bold'>
                  IQ Option
                </span>
                <div className='text_slate fs10 position-relative z-1 mb-2'>
                  Full Version 59MB
                </div>
                <Link to={"#"} className='platform_btn d-flex align-items-center justify-content-center gap-2 mb-2'>
                  <img src={ios} width={"30px"} alt="" />
                  <img src={iosimg} className='' alt="" />
                </Link>
                <Link to={"#"} className='platform_btn d-flex align-items-center justify-content-center gap-2 mb-3'>
                  <img src={google} width={"30px"} alt="" />
                  <img src={googleimg} className='' alt="" />
                </Link>
                <span className='text_slatedk fs14 position-relative z-1 fw-bold'>
                  IQ Lite
                </span>
                <div className='text_slate fs10 position-relative z-1 mb-2'>
                  Smaller size (1.6 MB), only the essentials
                </div>
                <Link to={"#"} className='platform_btn d-flex align-items-center justify-content-center gap-2 mb-3'>
                  <img src={google} width={"30px"} alt="" />
                  <img src={googleimg} className='' alt="" />
                </Link>
              </div>
            </div> */}
            <div className='col-12 mt-4 mb-4'>
              <div className='row'>
                {/* <div className='col-6 '>
                  <img src={fb} className='me-2' width={"30px"} height={"30px"} alt="" />
                  <img src={tele} className='me-2' width={"30px"} height={"30px"} alt="" />
                  <img src={twitter} className='me-2' width={"30px"} height={"30px"} alt="" />
                  <img src={insta} className='me-2' width={"30px"} height={"30px"} alt="" />
                  <img src={youtube} className='me-2' width={"30px"} height={"30px"} alt="" />
                </div> */}
                <div className='col-12'>
                  <div className='text-center text_orng'>
                   Beeta © 2024
                  </div>
                </div>

              </div>
            </div>
          </div>



        </div>

      </div>
    </>
  )
}

export default LoginFooter