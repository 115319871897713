import axios from 'axios';  // Corrected import statement
const API_URL = process.env.REACT_APP_API_URL;
export const upload = process.env.REACT_APP_UPLOAD;
export const css = 'https://beeta.ai'

// export const upload = 'http://3.104.154.173:5000/uploads'
// const API_URL = 'http://3.104.154.173:5000/api/v1'

export const get_user_single_data = async (id, token) => {
    try {
        const { data } = await axios.get(`${API_URL}/user/${id}`, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};

export const update_user_profile_image = async (id, token, submitData) => {
    try {
        const { data } = await axios.post(`${API_URL}/user/userprofilewithImage/${id}`, submitData, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};

export const update_user_profile_data = async (id, token, submitData) => {
    try {
        const { data } = await axios.post(`${API_URL}/user/userprofile/${id}`, submitData, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};


export const get_user_coin_data = async (id, token) => {
    try {
        const { data } = await axios.get(`${API_URL}/withdrawalcoin/user/${id}`, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};

export const user_withdrawal_store = async (id, token, submitData) => {
    try {
        const { data } = await axios.post(`${API_URL}/withdrawalcoin/${id}`, submitData, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};


export const user_wallet_get = async (token, submitData) => {
    try {
        const { data } = await axios.post(`${API_URL}/wallet/`, submitData, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};


export const get_user_deposite_data = async (id, token) => {
    try {
        const { data } = await axios.get(`${API_URL}/depositcoin/${id}`, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};

export const get_user_balance_data = async (id, token, coinId) => {
    try {
        const { data } = await axios.get(`${API_URL}/user/balance/${id}/?coinId=${coinId}`, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};



export const get_user_account_request_data = async (id, token) => {
    try {
        const submitData = {
            status: 1
        }
        const { data } = await axios.post(`${API_URL}/user/deleteRequest/${id}`, submitData, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};

export const get_user_trading_history_data = async (id, token, coinId) => {
    try {
        const { data } = await axios.get(`${API_URL}/user/tradehistory/${id}/?coinId=${coinId}`, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};

export const get_leader_of_the_week_data = async (id, token) => {
    try {
        const { data } = await axios.get(`${API_URL}/user/leaders-of-the-week/${id}`, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};

export const add_balance_in_the_user_data = async (id, token, submitData) => {
    try {
        const { data } = await axios.post(`${API_URL}/user/praciseaccount/${id}`, submitData, {  // Removed duplicate `/api/v1/`
            headers: {
                'token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};


export const send_user_forget_mail = async (submitData) => {
    try {
        const { data } = await axios.post(`${API_URL}/user/forget-password-request/`, submitData, {  // Removed duplicate `/api/v1/`
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};


export const update_user_forget_mail = async (submitData) => {
    try {
        const { data } = await axios.post(`${API_URL}/user/update-password-request/`, submitData, {  // Removed duplicate `/api/v1/`
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};



export const verify_user_account = async (submitData) => {

    
    try {
        const { data } = await axios.post(`${API_URL}/user/verify-account/`, submitData, {  // Removed duplicate `/api/v1/`
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error(error); // Log the error for debugging
        return error.response ? error.response.data : error; // Return error response if available
    }
};









