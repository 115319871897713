import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./TradingGraph.css"
import { faCircleQuestion, } from "@fortawesome/free-solid-svg-icons"


const Valuechanger = ({ lable , onValueChange}) => {
  const [value, setValue] = useState(1);

  const incrementValue = () => {
    const newValue = value + 1;
    setValue(newValue);
    onValueChange(newValue); // Pass value to parent
  };

  const decrementValue = () => {
    const newValue = value > 1 ? value - 1 : 1; // Ensure value does not go below 1
    setValue(newValue);
    onValueChange(newValue); // Pass value to parent
  };

  const handleInputChange = (e) => {
    let inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 1) {
      setValue(inputValue);
      onValueChange(inputValue); // Pass value to parent
    } else {
      setValue(1);
      onValueChange(1); // Ensure it stays above 1
    }
  };
  return (
    <>
      <div className='quant_changer d-flex  '>
        <div className='lable_n_input text-white'>
          <div className='lable d-flex align-items-center gap-2 fs14 text-white'>
            {lable}    <FontAwesomeIcon icon={faCircleQuestion} role='button' className="" />

          </div>
          <div>
            <input
              type="number"
              className="change_input w-100 text-white"
              value={value}
              onChange={handleInputChange} // Input handler
              min="1" // Enforces at least 1 in the input field
            />
          </div>
        </div>
        <div className='inc_dec_btns'>
          <button className='quant_changer_btn inc btn' onClick={incrementValue}>
            +
          </button>
          <button className='quant_changer_btn dec btn' onClick={decrementValue}>
            -
          </button>
        </div>
      </div>
    </>
  )
}

export default Valuechanger