import React, { useEffect, useState } from 'react'
import TradingHistroy from './TradingHistroy';
import ChartnSupport from './ChartnSupport';
import { get_user_coin_data } from '../../../common/Api';
import Leaderboard from './Leaderboard';
import Promo from './Promo';

const Offcanvas = ({ value }) => {
  const [coin, setCoin] = useState([])
  const user = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  const getCoin = async () => {
    try {
      const data = await get_user_coin_data(user._id, token)
      console.log(data)
      setCoin(data.coin)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCoin()
  }, [])
  return (
    <>
      {/* offcanvas  */}


      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ backgroundColor: "rgb(39 49 69)", maxWidth: "320px" }}>
        {
          value == 0 ? <TradingHistroy coins={coin} /> : value == 1 ? <ChartnSupport /> : value == 2 ? <Leaderboard /> : value == 3 ? <Promo /> : <TradingHistroy coins={coin} />
        }


      </div>
    </>
  )
}

export default Offcanvas