import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faCaretUp, faChartColumn, faClockRotateLeft, faEllipsis, faFire, faGraduationCap, faMedal, faMessage } from '@fortawesome/free-solid-svg-icons';
import { IoMdClose } from "react-icons/io";
import coinlogo from "../../../images/iq/litecoinlogo.png";
import { GoDotFill } from "react-icons/go";

const ChartnSupport = () => {
        return (
                <>
                        <div class="offcanvas-header">
                                <h6 class="offcanvas-title text-white fw-normal" id="offcanvasExampleLabel">
                                        Support
                                        <p className='fs11 mb-0 d-flex align-items-center gap-1 text_slate'> <GoDotFill className='text_green' /> Online</p>
                                </h6>

                                <IoMdClose className='fs-4 text-white ms-auto' data-bs-dismiss="offcanvas" aria-label="Close" />
                        </div>
                        <div class="offcanvas-body" style={{textAlign:"center"}}>

                                {/* <div className='querryarea'>
                                        <p className='fs14 text_slate text-capitalize text-center fw-semibold my-5'>
                                                Any Questions ? Feel free to ask and we'll answer you quickly!
                                        </p>
                                </div>
                                <div className='predef_quest px-2'>
                                        <button className='btngrey  border-0  p-2 w-100 text-white fs14 mb-2'>
                                                i would like to deposit
                                        </button>
                                        <button className='btngrey  border-0  p-2 w-100 text-white fs14 mb-2'>
                                                Tell me about trading
                                        </button>
                                        <button className='btngrey  border-0  p-2 w-100 text-white fs14 mb-2'>
                                                How can i withdraw
                                        </button>
                                        <button className='btngrey  border-0  p-2 w-100 text-white fs14 mb-2'>
                                                About account verification
                                        </button>
                                </div> */}
                                <h6 className='fs14 text_slate text-capitalize text-center fw-semibold my-5' style={{ lineHeight: "20px" }}>
                                        Need to get in touch ? <br />
                                        Contact us on <br />
                                </h6>
                                <button  className='btngrey  border-0  p-2 w-100 text-white fs14 mb-2'> beeta@gmail.com </button>
                        </div>
                </>
        )
}

export default ChartnSupport