// TradingViewWidget.jsx
import React, { useEffect, useRef, memo, useState } from 'react';


function TradingViewWidget() {
  const container = useRef();
  const [realTimeData, setRealTimeData] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      "container_id": "technical-analysis-chart-demo",
      "width": "100%",
      "height": "100%",
      "autosize": true,
      "symbol": "BINANCE:LTCBTC", // Use a cryptocurrency for real-time data
      "interval": "D",
      "timezone": "exchange",
      "theme": "dark",
      "style": "1",
      "withdateranges": true,
      "hide_side_toolbar": false,
      "allow_symbol_change": true,
      "save_image": false,
      "studies": [
        "MASimple@tv-basicstudies"
      ],
      "show_popup_button": true,
      "popup_width": "1000",
      "popup_height": "650",
      "support_host": "https://www.tradingview.com"
    });

 
    });

    // Cleanup
   

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
      
    </div>
  );
}

export default memo(TradingViewWidget);
