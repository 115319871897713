import React, { useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { IoCloseCircle } from "react-icons/io5";
import { MyContext } from './Context';
import { get_user_coin_data, get_user_deposite_data, user_wallet_get } from '../../common/Api';
import toast from 'react-hot-toast';
import Web3 from 'web3';
import moment from 'moment';
import GatewaySlider from './GatewaySlider';
import LoginFooter from './LoginFooter';
import { FaCopy } from "react-icons/fa";
import axios from 'axios';


const DepositHistory = () => {

  // const { value } = useContext(MyContext);
  const { value, updateValue } = useContext(MyContext); // Destructure updateValue
  const user = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  const [selectedCoin, setSelectedCoin] = useState('6708ef77652a6be8b13b34a1');
  const [coin, setCoin] = useState([])
  const [address, setAddress] = useState('')
  const [deposit, setDeposit] = useState([])
  const apiUrl = process.env.REACT_APP_API_URL;
  const intervalTime = 10000; // Time interval in milliseconds (10 seconds)


  const checkApi = async () => {
    try {

      const response = await axios.post(`${apiUrl}/user/update-deposits`, {
        userId: user?._id,
        coinId: selectedCoin
      });

      if (response.status === 200) {
        getUserDepositeWallet();
      } else {
        console.error('API response failed with status:', response.status);
      }

      console.log('API response:', response.data);
    } catch (error) {
      console.error('Error checking API:', error);
    }
  };

  const handleClose = () => {
    updateValue(false); // Or set it to whatever value indicates the closed state
  };

  const userGetToken = async () => {
    try {
      const data = await get_user_coin_data(user._id, token)
      //console.log(data)
      setCoin(data.coin)
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    userGetToken();
    getUserWallet();
    getUserDepositeWallet();
    checkApi();

    // Set interval to call the API periodically
    const intervalId = setInterval(() => {
      checkApi();
    }, intervalTime);

    // Cleanup the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [user._id, token])

  const handleSelectChange = async (event) => {
    setSelectedCoin(event.target.value);
    await getUserWallet(event.target.value)
  };
  const getUserWallet = async () => {
    try {
      const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
      const account = web3.eth.accounts.create();
      const submitData = {
        userId: user._id,
        coinId: selectedCoin,
        address: account.address,
        key: account.privateKey
      }
      const data = await user_wallet_get(token, submitData)
      console.log('wallet address => ', data)
      setAddress(data.data.address)
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  const getUserDepositeWallet = async (req, res) => {
    try {
      const data = await get_user_deposite_data(user._id, token)
      console.log(data)
      setDeposit(data.data)
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  const handleCopy = () => {
    if (!document.hasFocus()) {
      console.error("Document is not focused. Cannot copy.");
      return;
    }

    navigator.clipboard.writeText(address)
      .then(() => {
        toast.dismiss();
        toast.success('Address Copied Successfully!.');
      })
      .catch(err => console.error('Failed to copy!', err));
  };

  return (
    <>
      <div className={`m-auto row   h-100 opacity-1  dep_show`} style={{ padding: "4rem" }} >

        <div className='col-xl-10 mx-auto'>
          <Row>
            <div>
              <h2 className='text_slate text-center mb-5'>
                Deposit History
              </h2>
            </div>
            <div className="col-xxl-4 col-xl-5 col-lg-5 deposit-qr-box mb-3">
              <div className="card p-4">
                <div className="card-body text_slate">
                  <div className="mb-3">
                    <div className="mb-1">
                      {/* <label className="form-label text_slate fw-semibold" htmlFor="transfer_amount">
                        Coin
                      </label>
                      <select id="coin" className="form-control" onChange={handleSelectChange} value={selectedCoin}>
                        {
                          coin?.map((items, i) => {
                            return (
                              <option key={i} value={items?._id}>{items?.name}</option>
                            )
                          })
                        }


                      </select> */}
                    </div>
                    <span id="withdrawal-total" className="text-danger" />
                  </div>
                  <div className="text-center ">
                    <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?data=${address}&size=100x100`}
                      className="qr-code img-thumbnail img-fluid mx-auto"
                    />
                  </div>
                  <div className="text-center mb-4">
                    <a
                      href="#"
                      className="btn scan-qr-btn btngrey text-white mt-4 "
                    >
                      USDT:BEP20
                    </a>
                  </div>
                  <h6 id="coins">
                    Deposit USDT:BEP20 fund will be added into your account.
                  </h6>
                  <p id="coins2">Sync : USDT : 0</p>
                  <div className="qr-code-box mt-4 d-flex align-items-center justify-content-between px-3">
                    <p className='mb-0 fs12'>{address} </p>
                    <div
                      onClick={handleCopy}
                      style={{ cursor: 'pointer' }}
                      tabIndex={0} // Makes the div focusable
                      onKeyDown={(e) => { if (e.key === 'Enter') handleCopy(); }} // Handle Enter key as well
                    >
                      <FaCopy />
                    </div>

                  </div>
                  <a
                    href=""
                    target="_blank"
                    className="view-explore mt-3 btn w-100 btngrey text-white"
                  >
                    View in Explore <i className="lni ms-1 lni-share" />
                  </a>
                  <p className="text_orng text-center mt-3">
                    {" "}
                    <i className="lni lni-warning" />
                    Syncing Latest Updates 0 Seconds. Please Wait For Alert !
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-8 col-xxl-7 col-lg-7 mb-3">
              <div className="card text_slate">
                <h5 className="card-header text_slate py-4 border-0">Deposit History</h5>
                <div className="table-responsive text-nowrap ">
                  <table className="table">
                    <thead className="text_slate">
                      <tr>
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Value</th>
                        <th>Transaction Hash</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {
                        deposit && deposit.length > 0 ? <>

                          {
                            deposit?.map((items, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{moment(items?.createdAt).format('DD MMMM YYYY')}</td>
                                  <td>{items?.amount}</td>
                                  <td className="text-center">
                                    <div className="actions">
                                      <a
                                        href={`https://bscscan.com/tx/${items?.hash}`}
                                        className="btn btngrey d-flex text-white justify-content-center"
                                        target="_blank"
                                      >
                                        Explore <i className="lni ms-1 lni-share" />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </> : <>
                          <tr>
                            <td colSpan="4" className="text-center fw-semibold">
                              No Deposits Found
                            </td>
                          </tr>
                        </>

                      }
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center"></div>
                </div>
              </div>
            </div>
          </Row>

        </div>

      </div>

    </>
  )
}

export default DepositHistory